import React from "react";

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_4016_107)">
      <path
        d="M18.4048 4.31616C16.7062 2.88948 14.5676 2.07045 12.3498 2L7.25412 15.4742L18.4048 4.31616Z"
        fill="black"
      />
      <path
        d="M19.6809 5.59314L13.2739 12.0044L19.6809 18.4157C22.77 14.6993 22.77 9.30076 19.6809 5.59314Z"
        fill="black"
      />
      <path
        d="M9.85038 3.54117L2.01763 11.3703C1.85922 13.889 2.65129 16.3725 4.24424 18.3276L9.85038 3.54117Z"
        fill="black"
      />
      <path
        d="M11.9978 13.2726L5.59079 19.6839C9.30474 22.775 14.6909 22.775 18.4048 19.6839L11.9978 13.2726Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_4016_107">
        <rect width="20" height="20" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
